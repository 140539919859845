import config from '../config';

class Page {
  constructor({
    appGroup = '',
    appName = '',
    menuItem = '',
    npTitleID = 'empty',
    npCommID = 'empty',
    productGrouping = 'empty',
    conceptID = 'empty',
    type = 'landing',
    analyticsEnabled = false
  }) {
    this.appGroup = appGroup || ''
    this.analyticsEnabled = analyticsEnabled
    this.appName = appName || ''
    this.menuItem = menuItem || ''
    this.version = config.kodama.analyticsEnv || 'dev'
    this.npTitleID = npTitleID
    this.npCommID = npCommID
    this.productGrouping = productGrouping
    this.conceptID = conceptID
    this.type = type
  }

  error() {
    this.type = 'error'
  }

  get object() {
    return {
      pageInfo: {
        pageName: `pspartners:${this.appGroup}:${this.appName}:${this.menuItem}`,
        destinationURL: window.location.href,
        sysEnv: 'web',
        version: this.version,
        context: `${this.npTitleID}:${this.npCommID}:${this.productGrouping}:${this.conceptID}`
      },
      category: {
        pageType: this.type
      }
    }
  }
}

const pageContext = (function () {
  let page;

  function create() {
    return new Page({
      appGroup: '',
      appName: '',
      menuItem: '',
      npTitleID: 'empty',
      npCommID: 'empty',
      type: 'landing'
    });
  }

  return {
    get: function () {
        if (!page) {
            page = create();
        }
        return page;
    }
  };
})();


export default pageContext

export { Page }