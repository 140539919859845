import axios from 'axios';
import config from '../config';
let languagesCache;

let apiBase = config.kodama.apiUrl;

function _getAllLanguages(){

    /* this directly makes a call to kodama-app-server*/
    return axios.get(`${apiBase}/metadata/v1/languages`).then(resp => {
       const data = resp.data;
       if (!languagesCache){
          languagesCache = data;
       }
       return resp.data
    });
}

function getAllLanguages(){
    if (!languagesCache){
       return _getAllLanguages();
    }
   return Promise.resolve(languagesCache);
}

function getContextMetadata(contextType, npId){
   let npContextType =  (contextType === 'NP_COMM_ID') ? 'npCommId' : 'npTitleId';
   const url = `${apiBase}/metadata/v1/${npContextType}/${npId}`;

   return axios.get(url).then((response) => response.data);
}

function saveNpIdMetadata(contextType, model){
   let npContextType =  (contextType === 'NP_COMM_ID') ? 'npCommId' : 'npTitleId';
   const url = `${apiBase}/metadata/v1/${npContextType}`;

   return axios.post(url, model).then(response => response.data);
}

export {getAllLanguages, getContextMetadata, saveNpIdMetadata};