import React from "react";
import './SupportedPlatformDescription.css';

export default class SupportedPlatformDescription extends React.Component {
  render = () => {
    const platFormEditable = this.props.platFormEditable

    const description = "Choose the PlayStation platform supported by your package to enable platform-specific configurations and operations."

    const tooltip = platFormEditable
        ? "You have privileged access which allows you to update supported platform data."
        : "You have view-only access which doesn't allow you to update the data."

    return (
        <React.Fragment>
          <div className="metadata-section metadata-section__title">
            <div className="row metadata-section-supported-platforms">
              <h4><b>Supported Platforms</b></h4>
              <div id='supported-platform-pill'>
                <div className={'pill' + (platFormEditable ? ' privileged'
                    : ' view-only')}>
                  {platFormEditable ? "PRIVILEGED" : "VIEW ONLY"}
                </div>
              </div>
              <div id="supported-platform-tooltip">
                <span sie-tooltip={tooltip}>
                    <span className="question-icon"></span>
                </span>
              </div>
            </div>
            <hr/>
            <div className="metadata-section__description">{description}</div>
          </div>
        </React.Fragment>
    )
  }
}