import React from 'react';
import { Loader } from '@sie/kodama-ui-components';

export default class LoadingSign extends React.Component {
   render(){
    const minHeight = window.innerHeight;
    const loadingDivStyles = {
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	alignItems: "center",
        minHeight: minHeight
    };
    const message = "Loading .... please wait."
    return <div style={loadingDivStyles}><Loader message={message}/></div>;
   }
}
