import React from 'react';
import {Modal} from 'react-bootstrap';
import {Button, TextField, Radio} from '@sie/kodama-ui-components';
import * as service from './NPCommService';
import './NPCommMetadataModal.css';
import Loader from '@sie/kodama-ui-components/lib/Loader';
import ImageUploader from './ImageUploader';
import FormSection from "./FormSection";
import SupportedPlatformDescription from "./SupportedPlatformDescription";
import config from "../config";

export default class NPTitleMetadataModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      loadError: false,
      saveError: false
    };
  }

  componentDidMount = () => {
    let npTitleId = this.props.npTitleId;
    let contextType = this.props.contextType;

    service.getContextMetadata(contextType, npTitleId).then(
        (npTitleMetadata) => {

          this.setState({
            loading: false,
            ...npTitleMetadata
          });

        }).catch(error => {
      this.setState({loading: false, loadError: true})
    });
  }

  handleNameChange = (event) => {
    const name = event.target.value;
    const nameError = (name.trim().length === 0)
        ? "Display Name must be present"
        : ((name.length > 100)
            ? "Display name cannot be greater than 100 characters" : null);
    this.setState({name, nameError});
  }

  handleImageChange = imageUrl => {
    this.setState({imageUrl});
  }

  handleSupportedPlatformChange = (e) => {
    const supportedPlatform = e.target.value
    this.setState({supportedPlatform});
  }

  onSave = () => {
    const npTitleMetadata = {
      id: this.state.id,
      name: this.state.name,
      imageUrl: this.state.imageUrl,
      writeAccess: this.state.editable,
      supportedPlatform: this.state.supportedPlatform
    };
    if (config.kodama.hideNPTitleSupportedPlatforms){
      delete npTitleMetadata.supportedPlatform
    }
    const contextType = this.props.contextType;
    this.setState({saving: true, saveError: false}, () => {
      service.saveNpIdMetadata(contextType, npTitleMetadata)
      .then(() => {
        this.setState({saving: false},
            () => this.props.onSaveComplete(npTitleMetadata));
      }).catch((error) => {
        this.setState({saving: false, saveError: true});
      });
    })
  };

  renderForm = () => {

    if (this.state.loadError) {
      return (
          <div className="alert alert-danger">An error occurred while loading
            metadata.</div>)
    }
    const {
      name, saveError, nameError, editable, platFormEditable, supportedPlatform
    } = this.state;

    const showSupportedPlatformSection = !config.kodama.hideNPTitleSupportedPlatforms &&
        (platFormEditable || (!platFormEditable && supportedPlatform === 'PSPC'))

    return (
        <React.Fragment>
          {saveError && <div className='alert alert-danger'>An error occurred
            while saving metadata.</div>}
          <FormSection title="Basic Information"
                       description="The reference name will be used for the display on the menu of the tool only. The setting will be shared with other users accessing the tool">
            <div className="row metadata-section">
              <div className="reference-name-container">
                <label>Reference Name</label>
                <div>
                  <div>
                    <Radio label="Specify a Name" checked="true"
                           disabled={!editable}/>
                  </div>
                  <div>
                    <TextField id="npTitle-name" defaultValue={name} size="big"
                               onChange={this.handleNameChange}
                               errormessage={nameError} disabled={!editable}/>
                  </div>
                </div>
                <Radio
                    label="Sync from product name on DevNet (Supported in a future release)"
                    disabled="true"/>
              </div>
              <div className="col-md-6"/>
            </div>
            <div className="row metadata-section metadata-section__image">
              <div className="col-md-12">
                <label>Image Icon</label>
                {this.state.imageUrl ? (
                    <p>Custom icon is uploaded.</p>
                ) : (
                    <p>Default icon is selected. {editable
                        ? 'Please upload a 50x50 PNG image to override it.'
                        : ''}</p>
                )}
              </div>
              <div className="col-md-6">
                <ImageUploader
                    imageUrl={this.state.imageUrl}
                    handleImageChange={this.handleImageChange}
                    contextType={this.props.contextType}
                    editable={editable}
                />
              </div>
            </div>
          </FormSection>
          {showSupportedPlatformSection &&
              <React.Fragment>
                <SupportedPlatformDescription
                    contextType={this.props.contextType}
                    platFormEditable={platFormEditable}
                />
                <div className="row metadata-section">
                  <div className="col-md-6">
                    <p>Supported Platforms</p>
                    <Radio
                        label="PS5"
                        id="ps5"
                        value="PS5"
                        disabled={!platFormEditable}
                        checked={supportedPlatform === 'PS5'}
                        onClick={this.handleSupportedPlatformChange}
                    />
                    <Radio
                        label="PSPC"
                        id="pspc"
                        value="PSPC"
                        disabled={!platFormEditable}
                        checked={supportedPlatform === 'PSPC'}
                        onClick={this.handleSupportedPlatformChange}
                    />
                  </div>
                </div>
              </React.Fragment>
          }
        </React.Fragment>
    );
  }

  renderFooter = () => {
    const {editable, saving, nameError, platFormEditable} = this.state;
    const saveDisabled = !!nameError;

    if (editable || platFormEditable) {
      return (
          <Modal.Footer>
            <Button bsStyle="secondary" disabled={!!saving}
                    onClick={this.props.onClose}>Cancel</Button>
            <Button bsStyle="primary" className={saving ? "sie-loading" : ""}
                    disabled={saveDisabled} onClick={this.onSave}>Save</Button>
          </Modal.Footer>
      );
    } else {
      return (
          <Modal.Footer>
            <Button bsStyle="secondary"
                    onClick={this.props.onClose}>Close</Button>
          </Modal.Footer>
      );
    }
  }

  render() {
    const {loading, loadError} = this.state;
    const npTitleId = this.props.npTitleId;
    return (
        <div>
          <Modal show={true} id="npComm-metadata-modal"
                 onHide={this.props.onClose} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>Metadata Manager for {npTitleId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {loading ? <Loader/> : this.renderForm()}
            </Modal.Body>

            {!loading && !loadError ? this.renderFooter() : ""}
          </Modal>
        </div>
    );
  }

}