import { MenuItem, Nav, NavDropdown, NavigationBar as Navbar, NavItem } from '@sie/kodama-ui-components';
import React, { Component } from 'react';
import { DataLayer, pageContext, userContext } from './analytics';
import config from './config';
import KodamaBreadcrumbs from './KodamaBreadcrumbs.js';
import { findMenuItem, getAppIdentifier, getCleanUrl, getCorrectPath, parseQueryParameters } from './utils';

const { MENU_ITEM_KEY, APP_ID_KEY, persistQueryParams } = config

/*
 * To resolve X23-651, this is added to the onClick so that the href within the menuItem would not cause a hard-refresh whenever the menu item is clicked
 * Since the react-bootstrap components chains onclicks (first with your onClick prop followed by their handleClick method which executes onSelect,
 * we should preventDefault since we contain a href.  This way, we can right click and navigate correctly and still contain expected Kodama functionality.
 */
const safePrevent = (e) => (e.preventDefault());

class NavigationBar extends Component {
    state = { activeDropdown: null }

    createNavigation = (menuItems, selectedMenuId) => (
        menuItems.map(m => this.createNavItem(m, selectedMenuId))
    )

    onDropdownToggle = (isOpen, event) => {
        // console.log("isOpen = ", isOpen, "; event = ", event.currentTarget, "; eventSource = ", eventSource);

        if (!isOpen){
           const elem = document.getElementById("multi-layer-" + this.state.activeDropdown);
           this.setState({ activeDropdown: null}, () => elem && elem.blur());
        } else {
           const id = event.currentTarget.id;
           const parts = id.split("-");
           this.setState({ activeDropdown: parseInt(parts[2], 10) });
        }
    }
    onDropdownBlur = (e) => {
        /*
         *  X23-490: In order for the dropdown to be closed during iframe interactions,
         *     we detect blur events and then close the active dropdown
         */

	    // The condition of closing the open dropdown is that the open tab cannot be the active tab

        const classNames = e.currentTarget.className.split(" ")
        const isOpen = classNames.includes("open");
        const isNested = classNames.includes("nested-dropdown");
        if(isOpen && !isNested){
            this.onDropdownToggle(false, e, { source: "rootClose" });
        }
    }
    constructSearchParameters(search){
        if (!search) return "";
        const queryObjs = parseQueryParameters(search.substr(1));
        const queryParamStr = Object.entries(queryObjs)
            .filter(entrySet => persistQueryParams.indexOf(entrySet[0]) !== -1)
            .map(tup => `${tup[0]}=${encodeURIComponent(tup[1])}`)
            .join("&");
        return queryParamStr.length === 0 ? "" : ("?" + queryParamStr);
    }
    constructUrlFromMenuItem(menuItem, appGroup){
        const path = getCorrectPath(menuItem.url);
        const appIdentifier = getAppIdentifier(menuItem.appId);
        return getCleanUrl(appGroup, appIdentifier, path)
    }
    createMenuItemHref = menuItem => {
	    const search = this.constructSearchParameters(this.props.history.location.search);
	    const basePath = this.constructUrlFromMenuItem(menuItem, this.props.applicationGroup);
        const href = `${basePath}${search}`;
	    return href;
    }
    dispatchMenuItem = eventKey => {
        const { history, applicationGroup, menuItems } = this.props;

        let redirectUrl = `/${applicationGroup}`, basePath = `/${applicationGroup}`;
        if (eventKey !== 0){
            const conditionMatch = (m) => (m.id === eventKey);
            const menuItem = findMenuItem(menuItems, conditionMatch );
            const search = this.constructSearchParameters(history.location.search);
            basePath = this.constructUrlFromMenuItem(menuItem, applicationGroup);
            redirectUrl = `${basePath}${search}`;


            const page = pageContext.get();
            page.type = 'landing';
            page.appName = getAppIdentifier(menuItem.appId);
            page.menuItem = menuItem.itemId;

            const user = userContext.get();
            const dataLayer = new DataLayer({ page, user });
            dataLayer.setEvent({
                id: menuItem.itemId,
                title: menuItem.title,
                position: '', // TODO: how to serialize position?
                type: menuItem.parentId ? 'dropdown' : 'tab',
                url: menuItem.url
            })
            dataLayer.send('clickEvent')
        }

        history.push(redirectUrl);

        if (basePath === history.location.pathname){
            this.props.triggerProxyRefresh();
        }
    }
    createNavItem = (menuItem, selected) =>{
        if (menuItem.subItems.length === 0){
            const href = this.createMenuItemHref(menuItem);
            return (
                <NavItem
                    key={menuItem.id} eventKey={menuItem.id}
                    href={href}
                    onSelect={this.dispatchMenuItem} onClick={safePrevent}
                >
                    {menuItem.title}
                </NavItem>
            );
        }
        const topHref = !menuItem.url ? "#" : this.createMenuItemHref(menuItem);
        return (
            <NavDropdown
                id={`multi-layer-${menuItem.id}`}
                key={menuItem.id} eventKey={menuItem.id} title={menuItem.title}
                href={topHref}
                open={this.state.activeDropdown === menuItem.id}
                onToggle={this.onDropdownToggle} onClick={safePrevent}
                onMouseLeave={this.onDropdownBlur}
            >
                {menuItem.subItems.map((item) => {
                    if (!item.subItems || item.subItems.length === 0){
                        const href = this.createMenuItemHref(item);
                        return (
                            <MenuItem
                                key={item.id} eventKey={item.id}
                                href={href}
                                onSelect={this.dispatchMenuItem} onClick={safePrevent}
                            >
                                {item.title}
                            </MenuItem>
                        );
                    }
                    const topLevelHref = !item.url ? "#" : this.createMenuItemHref(item)
                    return (
                        <NavDropdown
                            id={`multi-layer-${item.id}`} className="nested-dropdown"
                            key={item.id} eventKey={item.id} title={item.title}
                            href={topLevelHref}
                            onMouseLeave={this.onDropdownBlur} onClick={safePrevent}
                        >
                            {item.subItems.map((thirdItem) => {
                                const link = this.createMenuItemHref(thirdItem);
                                return (
                                    <MenuItem
                                        key={thirdItem.id} eventKey={thirdItem.id}
                                        active={(selected && selected.id === thirdItem.id)}
                                        href={link}
                                        onSelect={this.dispatchMenuItem} onClick={safePrevent}
                                    >
                                        {thirdItem.title}
                                    </MenuItem>
                                );
                            })}
                        </NavDropdown>
                    );
                })}
            </NavDropdown>
        );
    }

    render(){
        const { menuItems, history, applicationGroup } = this.props;
        /* TODO: optimize this */
        let conditionMatch = m => (
            getCleanUrl(applicationGroup, getAppIdentifier(m.appId), getCorrectPath(m.url)) === history.location.pathname
        );
        if (history.location.search){
            const queryObj = parseQueryParameters(history.location.search.substr(1));
            if (queryObj[MENU_ITEM_KEY] && queryObj[APP_ID_KEY]){
                conditionMatch = m => (
                    m.itemId === queryObj[MENU_ITEM_KEY] &&
                    m.appId === queryObj[APP_ID_KEY]
                );
            }
        }
        let selectedMenuItem = findMenuItem(menuItems, conditionMatch);
        let activeKey = !selectedMenuItem ? 0 : selectedMenuItem.id;
        return (
            <React.Fragment>
                <Navbar fluid={true}>
                    <Nav activeKey={activeKey}>
                        { this.createNavigation(menuItems, selectedMenuItem) }
                    </Nav>
                </Navbar>
                <div className='container-fluid breadcrumb-container'>
                    {
                        activeKey !== 0
                        &&
                        <KodamaBreadcrumbs {...this.props} onMenuSelection={this.dispatchMenuItem} selectedMenuItem={activeKey} />
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default NavigationBar;
