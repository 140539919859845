import React from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Glyphicon } from 'react-bootstrap';
import { Status } from '@sie/kodama-ui-components';
import 'react-dropzone-uploader/dist/styles.css';
import './ImageUploader.css';
import { DefaultContent, DisabledContent, UploadedContent } from './ImageUploaderSupport';

const ERROR_SIZE = 'image size should be 50x50.';
const ERROR_FORMAT = 'only PNG format is accepted.';

export default class ImageUploader extends React.Component {
  state = { error: '' }

  handleChangeStatus = ({ file, remove }, status) => {
    if (status === 'done') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.props.handleImageChange(reader.result);
        this.setState({ error: '' });
      }
      reader.onerror = err => {
        this.props.handleImageChange('');
        this.setState({ error: 'Failed to upload icon: cannot load image.' });
      }
    } else if (status === 'rejected_file_type') {
      this.props.handleImageChange('');
      this.setState({ error: `Failed to upload icon: ${ERROR_FORMAT}` });
    } else if (status === 'error_validation') {
      this.props.handleImageChange('');
      this.setState({ error: `Failed to upload icon: ${ERROR_SIZE}` });
      remove();
    }
  }

  validateFile = ({ meta }) => {
    return meta.width !== 50 || meta.height !== 50;
  }

  render() {
    if (this.props.imageUrl) {
      return (
        <div className="context-image-uploader image-uploader">
          <div className="preview-image">
            <UploadedContent imageUrl={this.props.imageUrl} />
          </div>
          <div className="bottom-bar">
            {this.props.contextType === "NP_COMM_ID" && <div className="left">npcomm-icon.png</div>}
            {this.props.contextType === "NP_TITLE_ID" && <div className="left">nptitle-icon.png</div>}
            {this.props.editable && (
              <div className="right">
                <span className="btn-click" onClick={() => this.props.handleImageChange('')}>
                  <Glyphicon glyph="remove" />
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
        <div className="context-image-uploader image-uploader">
          <Dropzone
            accept="image/png"
            maxFiles="1"
            multiple={false}
            validate={this.validateFile}
            onChangeStatus={this.handleChangeStatus}
            inputContent={this.props.editable ? DefaultContent : DisabledContent}
            SubmitButtonComponent={null}
            PreviewComponent={null}
            disabled={!this.props.editable}
          />
          <div className="bottom-bar">
            {this.state.error && (
              <React.Fragment>
                <div className="left"><Status type="error" message={this.state.error} /></div>
                <div className="right" style={{color: '#E41017'}}>
                  <span className="btn-click" onClick={() => this.setState({ error: '' })}>
                    <Glyphicon glyph="remove" />
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      );
  }
}
