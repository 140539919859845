import React, {Component} from 'react';
import Breadcrumb from 'react-bootstrap/lib/Breadcrumb';
import './KodamaBreadcrumbs.css';
import AppBreadcrumbs from './AppBreadcrumbs';

class KodamaBreadcrumbs extends Component{
	constructor(props){
		super(props);
		this.renderItems = this.renderItems.bind(this);
		this.evaluateArray = this.evaluateArray.bind(this);
		this.evaluateItem = this.evaluateItem.bind(this);
		this.selectedMenuItem = this.selectedMenuItem.bind(this);
	}

	shouldComponentUpdate(newprops, newstate){
		// console.log('shouldComponentUpdate');
		if(this.props.selectedMenuItem === newprops.selectedMenuItem
		&& this.props.menuItems === newprops.menuItems){ return false}
		return true;
	}

	evaluateArray(items, breadCrumbs = []){
		for(let i = 0; i < items.length; i++){
			let item = this.evaluateItem(items[i], breadCrumbs);
			if(item){
				breadCrumbs.push(this.toCrumb(item));
				return breadCrumbs;
			}
		}
		return null;
	}

	evaluateItem(item, breadCrumbs){
		if(item.id === this.props.selectedMenuItem){
			// console.log('match:'+item.id);
			return item;
		}
		if(item.subItems){
			breadCrumbs = this.evaluateArray(item.subItems, breadCrumbs);
			if(breadCrumbs){
				return item;
			}
		}
		return null;
	}

	toCrumb(item){
		return {
			title: item.title,
			href:	item.href,  // not used.  selectedMenuItem() used to handle click event
			id: item.id,
			linked: item.subItems && item.subItems.length?false:true  // TODO: this implementation does not support clickable parent menu items.  Think that is a requirement.
		}
	}

	selectedMenuItem(event){
		// console.log('handle click '+event.target.id);
		this.props.onMenuSelection(Number(event.target.id));
	}

	renderItems(){
		if(this.props.menuItems && this.props.menuItems.length && this.props.selectedMenuItem >= 0){
			let items = this.evaluateArray(this.props.menuItems);
			let crumbs = [];
			if(items){
				for(let i = 0; i < items.length; i++){
					if(items[i].linked){
						crumbs.push(<Breadcrumb.Item id={items[i].id} key={items[i].id} onClick={this.selectedMenuItem} href={items[i].href} title={items[i].title}>{items[i].title}</Breadcrumb.Item>);
					}else{
						crumbs.push(<Breadcrumb.Item id={items[i].id} key={items[i].id} title={items[i].title} active>{items[i].title}</Breadcrumb.Item>);
					}

				}
			}
			// TODO: selecting home breadcrumb item should open dashboard.  Integrate with dashboard
			// if(this.props.selectedMenuItem > 0 && this.props.menuItems[0]){
			// 	let item = this.props.menuItems[0];
			// 	crumbs.push(<Breadcrumb.Item id={item.id} key={item.id} href={item.href} onClick={this.selectedMenuItem} title='Home'>Home</Breadcrumb.Item>);
			// }
			return crumbs.reverse();
		}
	}

	render(){
		// console.log('rendering KodamaBreadcrumbs');
		return(
					<Breadcrumb>
						{this.renderItems()}
						<AppBreadcrumbs key={this.props.selectedMenuItem}/>
					</Breadcrumb>
		)
	}
}

export default KodamaBreadcrumbs;
