import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import Dispatcher from './Dispatcher';
//import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'

// Possible inspiration for a global catch all
// https://stackoverflow.com/questions/42606404/nesting-switch-components-with-global-nomatch-component-in-react-router-v4

ReactDOM.render(<Router><Dispatcher /></Router>, document.getElementById('root'));
//registerServiceWorker();
unregisterServiceWorker()
