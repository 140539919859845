import axios from 'axios';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import { getContextMetadata, fetchMenuItems } from './clientService';
import config from './config';
import { createGlobalSession, globalSession, initPartnerGUI } from './GlobalSession';
import Homepage from './Homepage';
import PageNotFound from './PageNotFound';
import {
   determineUserLanguage, forceReload, getAppIdentifier, getCleanUrl,
   getCorrectPath, parseQueryObj, parseQueryParameters, findFirstMenuItem,
   determineCorrectUrlBasedOnPathname, determineCorrectUrlBasedOnQueryParameters
} from './utils';
import { userContext, pageContext } from './analytics';

const { APP_ID_KEY, MENU_ITEM_KEY, CONTEXT_VALUE } = config;

const initialState = {
   isAuthenticated: false,
   menuItems: [],
   contextError: "",
   contextMetadata: null
};

var kodamaAPIBase = config.kodama.apiUrl;

class App extends Component {
   constructor(props){
      super(props);
      this.state = initialState;
      this.historyListener = null;
      this.requestInterceptor = axios.interceptors.request.use((config) => {
         config.headers['X-App-Group'] = this.props.match.params.applicationGroup;
         return config;
      });
   }

   componentWillUnmount(){
      if (this.responseInterceptor) axios.interceptors.response.eject(this.responseInterceptor);
      axios.interceptors.request.eject(this.requestInterceptor);
      window.removeEventListener("message", this.refreshContextMetadata);
      this.historyListener(); // remove historyListener
   }
   redirectToLogin = () => {
      /* Apparently the getIdToken will redirect user to the login page if it does not exist so that is why we do that here */
      return globalSession.getIdToken()
         .catch(e => {
            console.log('failed to get ID Token ' + e);
            globalSession.signOut();
         });
   }
   componentWillUpdate(nextProps, nextState){
      /* TODO: remove the then callback if we hook up the this.refreshSession callback into GSM  */
      if(nextState.isAuthenticated && !this.state.isAuthenticated){
         const func = () => {
	         this.keepSessionAlive().then(this.refreshSession);
            //console.log('refreshing kodama session: '+new Date());
            //this.createKodamaSession();
         }
         // token expiration time is set to 20 min in kodama server, so try to refresh early enough before expired (15 min)
         this.refreshTokenPollingFunc = setInterval(func, 900000);
         // ONLY add the response interceptor IFF we have successfully created a Kodama session
         this.responseInterceptor = axios.interceptors.response.use((response) => {
            return response;
         }, (error) => {
            if (error.response.status === 401){
               this.props.sendNotification({
                  type: "error",
                  message: "Your Kodama session has ended. Click the button to continue",
                  actionText: "Refresh Kodama session",
                  actionCallback: forceReload
               });
            }
            return Promise.reject(error);
         });
      }
   }
   createKodamaSession = (idToken) => {
      if(idToken && idToken.length > 3){
         return axios.post(kodamaAPIBase + '/token/v1/create', null, {
            headers: {
               Authorization: `Bearer ${idToken}`
            }
         }).then(response => response.data);
      }

      return Promise.reject({ error: "Unexpected id token value" });
   }

   keepSessionAlive = () => {
      const result = globalSession.sessionKeepAlive();
      if(!result || typeof result.then !== "function"){
         return Promise.resolve("");
      }

      return result;
   }

   // X23-366 fix to account for iframe's influence on history
   handleBack = () => {
      const { history } = this.props;
      if(!this.historyListener){
         this.historyListener = history.listen((location, action)=>{
            //console.log(`current URL is ${location.pathname}${location.search}${location.hash}${location.key}`);
            //console.log(`last navigation action is ${action}`);
            if(action === 'POP'){
               history.goBack();
            }
         });
      }
   }
   refreshContextMetadata = (event) => {
      if(event.data.message === "CONTEXT_METADATA_UPDATED"){
         this.reloadContextMetadata();
      }
   }

   reloadContextMetadata = () => {
      const { location, appGroupConfig } = this.props;
      getContextMetadata(location, appGroupConfig).then(contextMetadata => this.setState({contextMetadata}));
   }
   componentDidMount(){
      const { authenticationComplete, createKodamaSession, props } = this;
      const { appGroupConfig } = props;
      window.addEventListener("message", this.refreshContextMetadata);
      createGlobalSession(appGroupConfig, this.signout, this.refreshSession).then(()=>{
         globalSession.getIdToken()
            .then(createKodamaSession)
            .then((userClaims)=> {
               initPartnerGUI(determineUserLanguage(userClaims.locale), appGroupConfig);
               authenticationComplete(userClaims);
            })
            .catch(error => {
               console.log("Error during global session",error)
               props.sendNotification({
                  type:"error",
                  message: "Sorry we could not create a session for you at this time. Please try again later",
                  actionText: "Retry",
                  actionCallback: forceReload
               });
            });
      });

      this.handleBack();
   }
   initAnalytics = (userClaims, appGroup, appGroupConfig) => {
      const user = userContext.get();
      const page = pageContext.get();

      user.setUser(userClaims);
      page.analyticsEnabled = appGroupConfig.analyticsEnabled
      page.appGroup = appGroup;
   }
   authenticationComplete = (userClaims) => { // TODO: refactor this
      const { isExact, params } = this.props.match;
      const { history, appGroupConfig, location } = this.props;
      const { search } = location;
      let contextValue = null;
      getContextMetadata(location, appGroupConfig).then((contextMetadata) => {
         if (appGroupConfig.contextType === 'NP_TITLE_ID') {
            contextMetadata = contextMetadata.filter(data => (
               !data.contextValue.startsWith("CWAA") &&
               !data.contextValue.startsWith("CUS")
            ))
            if (contextMetadata.length === 0) throw new Error('No valid NP Title Id found')
         }
         if (contextMetadata.length > 0){
            if (search){
               const queryObjs = parseQueryParameters(search.substr(1));
               contextValue = ( !queryObjs[CONTEXT_VALUE] || !contextMetadata.map(d => d.contextValue).includes(queryObjs[CONTEXT_VALUE]))?contextMetadata[0].contextValue:queryObjs[CONTEXT_VALUE]
            } else {
               contextValue = contextMetadata[0].contextValue;
            }
         }
         fetchMenuItems(contextValue).then((menuItems) =>  {
            this.initAnalytics(userClaims, params.applicationGroup, appGroupConfig);
            if(menuItems.length > 0){
           	   /** This will be true IFF /:applicationGroup is an exact match **/
           	   if (isExact){
           	      this.setState({ isAuthenticated: true, menuItems, contextMetadata }, () => {
                     const firstMenuItem = findFirstMenuItem(menuItems)
                     let queryStr = search;
                     if (search){
                        let queryObj = parseQueryParameters(search.substr(1));
                        queryObj[CONTEXT_VALUE] = contextValue;
                        queryStr = parseQueryObj(queryObj);
                     } else {
                        if (contextMetadata.length > 0){
                           queryStr = "?"+CONTEXT_VALUE+"="+contextValue;
                        }
                     }
                     const appId = getAppIdentifier(firstMenuItem.appId);
                     const path = getCorrectPath(firstMenuItem.url);
                     const url = getCleanUrl(params.applicationGroup, appId, path);
                     history.push(url + (queryStr ? queryStr : ""));
           	     });
           	   } else {
           	      if (search){
                     const queryObj = parseQueryParameters(search.substr(1));

           		      if(queryObj[APP_ID_KEY] && queryObj[MENU_ITEM_KEY]){
                         const url = determineCorrectUrlBasedOnQueryParameters(menuItems, queryObj, params.applicationGroup)

                         if (contextMetadata.length > 0){
                           queryObj[CONTEXT_VALUE] = contextValue;
                         }

           		         // since this is assumed to be from a hard refresh and url has been revised, remove these tracking query parameters
           		         delete queryObj[APP_ID_KEY];
           		         delete queryObj[MENU_ITEM_KEY];

           		         // Due to a potential async issues, we set the timeout for updating state so that the changes in history can propagate first.
           		         history.push(url+parseQueryObj(queryObj));
           		         setTimeout(this.setState({ isAuthenticated: true, menuItems, contextMetadata }), 500);
           		      } else {
                        if (contextMetadata.length > 0){
                           queryObj[CONTEXT_VALUE] = contextValue;
                           const url = determineCorrectUrlBasedOnPathname(menuItems, location.pathname, params.applicationGroup)
                           history.push(url+parseQueryObj(queryObj));
                           setTimeout(() => this.setState({ isAuthenticated: true, menuItems, contextMetadata }), 500);
                        } else {
                           this.setState({ isAuthenticated: true, menuItems, contextMetadata });
                        }
           		      }
           	      } else {
                     if (contextMetadata.length > 0){
                        const url = determineCorrectUrlBasedOnPathname(menuItems, location.pathname, params.applicationGroup)
                        history.push(url+"?"+CONTEXT_VALUE+"="+ contextValue);
                        setTimeout(() => this.setState({ isAuthenticated: true, menuItems, contextMetadata }), 500);
                     } else {
           		         this.setState({ isAuthenticated: true, menuItems, contextMetadata });
                     }
           	      }
           	   }
            } else {
               history.push(location.pathname+"?"+CONTEXT_VALUE+"="+ contextValue);
               setTimeout(() => this.setState({ isAuthenticated: true, menuItems, contextMetadata }), 500);
            }
          }).catch((err) => {
              console.log("Error while retrieving navigation menu: ", err);
              this.setState({isAuthenticated:true, menuItems: null, contextMetadata, contextError: "" });
          });
       }).catch((err)=>{
          console.log("Error with context metadata");
          this.setState({isAuthenticated: true, contextError: err.message, contextMetadata: null });
       });
   }
   onUpdateMenuItems = (menuItems) => {
      this.setState({menuItems});
   }

   /* triggered in GSM signOut */
   signout = () => {
      console.log("local signout triggered by GSM")
      // do not rely on calling methods to handle exceptions.
      return axios.post(kodamaAPIBase + '/logout')
         .then(() => {
            console.log("local signout completed successfully.");
            return {"status":"success"};
         })
         .catch((error) =>{
            console.log("Error encountered while attempting to log out of Kodama: "+ error);
            return {"status":"error"};
         });
   }

   /* triggered in GSM refreshSession()*/
   refreshSession = (token) => {
      return new Promise((resolve)=>{
         //// TODO: consider creating kodama session here
         axios.post(kodamaAPIBase + '/token/v1/validate')
            .then(()=>{return token})
            .catch((error)=>{
               if(error && error.response && error.response.status && error.response.status !== 401) {
                  //console.log("Error encountered refreshing kodama session: "+ error);
                  //alert("An error was encountered refreshing kodama session. Redirecting you back to login screen...");
                  globalSession.signOut();
               }
            });
      });
   }

   render() {
      const { match, sendNotification, appGroupConfig } = this.props;
      const { applicationGroup } = match.params;
      return (
         <div>
            <Switch>
               <Route render={props => (
                  <Homepage
                     {...this.state}
                     sendNotification={sendNotification}
                     {...props}
                     onUpdateMenuItems={this.onUpdateMenuItems}
                     applicationGroup={applicationGroup}
                     appGroupContextType={appGroupConfig.contextType}
                     onMetadataChange={this.reloadContextMetadata} />
               )} />
               <Route component={PageNotFound} />
            </Switch>
	      </div>
      );
   }
}

export default App;
