import React from 'react';

class PageNotFound extends React.Component {
   render(){
	return (<div>
                    <img src="https://s3.amazonaws.com/production-wordpress-assets/blog/wp-content/uploads/2017/10/18183509/404-not-found.png" alt="Page Not Found" />
                    <p>{this.props.message}</p>
               </div>);
   }
}

PageNotFound.defaultProps = {
   message: "The page you are looking for cannot be found"
};

export default PageNotFound;
