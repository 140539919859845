import React from 'react';
import defaultImg from './images/default-context-img.svg';
import defaultOptionImg from './images/default-context-option.svg';

const ContextViewer = ({contextMetadata, isOption}) => {
   const imgUrl = (!contextMetadata.imgUrl)?(isOption?defaultOptionImg:defaultImg):contextMetadata.imgUrl;
   const img = <span className="context-viewer-img"><img src={imgUrl} alt="npcomm icon" /></span>;
   return (
     <div className="context-viewer-metadata-container">
         {img}
         <div>
           {!isOption && contextMetadata.contextType === "NP_COMM_ID" && <span className="context-viewer-context-type">NP Communication ID </span>}
           {!isOption && contextMetadata.contextType === "NP_TITLE_ID" && <span className="context-viewer-context-type">NP Title ID </span>}
           <div>
             <span className="context-viewer-value">{contextMetadata.contextValue}</span>
             <span className="context-viewer-type">{contextMetadata.displayName}</span>
            </div>
         </div>
     </div>
   );
}

export default ContextViewer;