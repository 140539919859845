const pkg = require('../../package.json')

class DataLayer {
  constructor({
    page,
    user
  }) {
    this.page = page
    this.user = user
  }

  get digitalData () {
    const digitalData = {
      page: this.page.object,
      user: this.user.object,
      pageInstanceID: `${pkg.name}:${pkg.version}`
    }
    if (this.event) {
      digitalData.event = this.event
    }
    return digitalData
  }

  setEvent({ id, title = null, position = '', type, url = null }) {
    this.event = [{
      eventInfo: {
        eventName: `select ${id}`,
        eventAction: 'click'
      },
      attributes: {
        assetName: null,
        moduleName: 'kodama UI header',
        position,
        elementType: type,
        label: title,
        linkURL: url,
        userActionFlag: 'true'
      }
    }]
  }

  send (eventType) {

    if(!this.page.analyticsEnabled){
      console.log("Analytics disabled, current data", this.digitalData)
      return;
    }

    window.digitalData = this.digitalData
    if (typeof window._satellite !== 'undefined') {
      window._satellite.track(eventType)
    }
  }
}

export default DataLayer