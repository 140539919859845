import React from 'react';
import PropTypes from 'prop-types';
import './LockStatusIndicator.css';

const possibleLockStatus = {
    UNLOCKED: { lock: 'Unlocked', lockStyle: 'unlocked'},
    LOCKED: { lock: 'Locked', lockStyle: 'locked'},
    PARTIALLY_LOCKED: { lock: 'Partially Unlocked', lockStyle: 'partially-locked'},
    [undefined]: { lock: 'N/A', lockStyle: 'none'}
};

const determineStatusText = (status, key) => {
    if (status) {
        return possibleLockStatus[status][key];
    }
    return possibleLockStatus[undefined][key];
}

const LockStatusIndicator = (props) => {
    const lockStyle = determineStatusText(props.lockStatus , "lockStyle");
    const lockText = determineStatusText(props.lockStatus , "lock");
    return (
        <div id='lock-status-container'>
            <div className={'lock-status-indicator ' + (props.lockStyle || lockStyle)}>{props.text || lockText}</div>
        </div>
    );
}

LockStatusIndicator.propTypes = {
    lockStatus: PropTypes.string,
    text: PropTypes.string,
    lockStyle: PropTypes.oneOf(['none','unlocked','partially-locked','locked']),
}

export default LockStatusIndicator;