import config from "./config";

const { isProdEnv }  = config;

const baseUrl = process.env.PUBLIC_URL;

const darkModeMatcher = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');

function changeFavicon() {
    const oldLink = document.getElementById('faviconProd');
    if (oldLink) {
        document.head.removeChild(oldLink);
    }
    const faviconLink = document.createElement('link');
    faviconLink.rel = 'shortcut icon';
    faviconLink.type = 'images/svg+xml';
    faviconLink.id = 'faviconProd';
    if (darkModeMatcher.matches) {
        faviconLink.href = `${baseUrl}/favicon-prod.svg?dark`;
    } else {
        faviconLink.href = `${baseUrl}/favicon-prod.svg?light`;
    }
    document.head.appendChild(faviconLink);
}

const isChromeBrowser = window.chrome && (window.navigator.vendor === 'Google Inc.');

function setUp() {
    if (isProdEnv && isChromeBrowser) {
        darkModeMatcher.addEventListener('change', changeFavicon);
    }
}

function tearDown() {
    if (isProdEnv && isChromeBrowser) {
        darkModeMatcher.removeEventListener('change', changeFavicon);
    }
}

export default {
    setUp,
    tearDown,
};
