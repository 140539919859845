import React from 'react';
import { Status } from '@sie/kodama-ui-components';

class ErrorMsg extends React.Component {
   render(){
       return (<div id="errorMsg" className="status-error"><Status type="error" message={this.props.message} /></div>);
   }
};

ErrorMsg.defaultProps = {
   message: "We have encountered a problem. Please contact an Administrator"
};

export default ErrorMsg;
