import React from 'react';
import { Notification } from '@sie/kodama-ui-components';

export default class NotificationMessage extends React.PureComponent {
   state = {
      notificationProps: {}
   };

   notify = props => {
      this.setState({notificationProps: props});
   }

   render(){
      return <Notification id="kodama-notification" {...this.state.notificationProps} />;
   }
};
