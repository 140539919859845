import React from "react";

export default class FormSection extends React.Component{

  render() {
    return (
      <React.Fragment>
        <div className="metadata-section metadata-section__title" >
          <h4><b>{this.props.title}</b></h4>
          <hr />
          <div className="metadata-section__description">{this.props.description}</div>
        </div>
        {this.props.children}
      </React.Fragment>
    )
  }
}