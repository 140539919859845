import { determineUserLanguage } from '../utils'

class User {
  constructor(options) {
    this.id = options.id
    this.locale = options.locale
    this.gpid = options.worksForCompanyID
    this.permission = options.permission
  }

  get object() {
    return {
      profile: {
        profileInfo: {
          profileID: this.id,
          language: determineUserLanguage(this.locale)
        },
        attributes: {
          gpid: this.gpid,
          visitorType: this.permission
        }
      }
    }
  }

  setPermission(permission = '') {
    this.permission = permission.toLowerCase()
  }

  setUser(userClaims) {
    this.id = userClaims.id
    this.locale = userClaims.locale
    this.gpid = userClaims.worksForCompanyID
  }
}

const userContext = (function () {
  let user;

  function create() {
    return new User({ id: '', locale: '', worksForCompanyID: '', permission: '' });
  }

  return {
    get: function () {
        if (!user) {
            user = create();
        }
        return user;
    }
  };
})();

export default userContext

export { User }