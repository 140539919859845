import config from './config';

const { KA_PATH_KEY, CONTEXT_VALUE } = config;

const getApplicationGroup = () => {
   const applicationGroup = global.location.pathname.substr(1).split("/")[0];
   if (applicationGroup === null || typeof applicationGroup === undefined || applicationGroup === ""){
      throw new Error("Error:  Application Group has not been detected");
   }
   return applicationGroup;
};

const getCorrectPath = (path) => {
   let leadingSlash = "/";
   if (!path) return leadingSlash;

   if (path.startsWith(leadingSlash)) return path;

   return leadingSlash + path;
};

const getAppIdentifier = (appId) => {
   return appId.split(":")[2];
};

const getCleanUrl = (applicationGroup, appIdentifier, path) => {
   return `/${applicationGroup}/app/${appIdentifier}${path}`.replace("#", "%23");
};

const adjustIframeSrcUrl = (landingPageUrl, additionalPath) => {
   if (!additionalPath){
      return landingPageUrl;
   }
   if (landingPageUrl.endsWith("/") && additionalPath.startsWith("/")){
      return landingPageUrl + additionalPath.substr(1);
   }
   if (!landingPageUrl.endsWith("/") && (!additionalPath.startsWith("/") && !additionalPath.startsWith("?") && !additionalPath.startsWith("#"))) {
      return landingPageUrl + "/" + additionalPath;
   }
   return landingPageUrl + additionalPath;
};

const parseQueryParameters = (queryParameterString) => {
   let keyValPairs = queryParameterString.split("&"), obj = {};
   keyValPairs.forEach( keyVal => {
      let tuple = keyVal.split("=");
      obj[tuple[0]] = (!tuple[1])?null:decodeURIComponent(tuple[1]);
   });
   return obj;
};

/*
 * This function only considers menu items that are not parents (e.g. not dropdown items)
 */
const findMenuItem = (menuItems = [], conditionMatch) => {
   for (let menuItem of menuItems) {
      if (menuItem.subItems.length === 0){
         if (conditionMatch(menuItem)) {
            return menuItem;
         }
         continue
      }
      for (let subItem of menuItem.subItems) {
         if (subItem.subItems.length === 0){
            if(conditionMatch(subItem)){
               return subItem;
            }
            continue
         }
         for (let tertiaryItem of subItem.subItems) {
            if(conditionMatch(tertiaryItem)){
               return tertiaryItem
            }
         }
      }
   }

   return;
};

function createQueryParamSegment(queryObj){
   return Object.entries(queryObj).map( tup => `${tup[0]}=${encodeURIComponent(tup[1])}`).join("&");
}

const parseQueryObj = (queryObj) => {
   if (typeof queryObj !== "object" || Object.keys(queryObj).length === 0){
      throw new Error("parseQueryObj function expects an object with at least one key/value pair");
   }
   var queryStr = createQueryParamSegment(queryObj);
   return "?"+queryStr;
};

const constructAdditionalPathForIframeSrc = (queryParamObject, contextType) => {
   if ((typeof queryParamObject !== "object") || Object.keys(queryParamObject).length === 0) return "";

   const additionalPath = (queryParamObject[KA_PATH_KEY])?queryParamObject[KA_PATH_KEY]:"";

   const clonedParamObj = Object.assign({}, queryParamObject);
   if (queryParamObject[CONTEXT_VALUE] && contextType){
      clonedParamObj[contextType] = queryParamObject[CONTEXT_VALUE];
      delete clonedParamObj[CONTEXT_VALUE];
   }

   delete clonedParamObj[KA_PATH_KEY];

   let queryValues = (Object.keys(clonedParamObj).length === 0)?"":createQueryParamSegment(clonedParamObj);

   if (additionalPath.indexOf("?") !== -1){
      return additionalPath + ((queryValues)?("&" + queryValues):queryValues);
   } else {
      return additionalPath + ((queryValues)?("?" + queryValues):queryValues);
   }
}

function constructContextSwitcherOptions(contextMetadataList = [], appGroupContextType){
   return contextMetadataList.map(contextMetadata => {
      /*
       * x24-1838 if object has field with null value,
       * destructuring will set field as null instead of
       * using default value
       */
      const {imageUrl, displayName, contextValue } = contextMetadata;
      return {
         value: contextValue,
         label: `${imageUrl ? imageUrl : ''}|${displayName}|${contextValue}|${appGroupContextType}`
      };
   });
}

const determineUserLanguage = function(locale){
   if (locale === "ja_JP"){
     return "ja-jp";
   }
   return "en-us";
}

const forceReload = () => window.location.reload(true)

function findFirstMenuItem(menuItems = []){
   const conditionMatch = (m) => m.subItems.length === 0;
   return findMenuItem(menuItems, conditionMatch)
}

function determineCorrectUrlBasedOnPathname(menuItems = [], pathname, applicationGroup){
   const conditionMatch = m => (
       getCleanUrl(applicationGroup, getAppIdentifier(m.appId), getCorrectPath(m.url)) === pathname
   );
   let selectedMenuItem = findMenuItem(menuItems, conditionMatch);
   if (!selectedMenuItem) {
      selectedMenuItem = findFirstMenuItem(menuItems);
      return getCleanUrl(applicationGroup, getAppIdentifier(selectedMenuItem.appId), getCorrectPath(selectedMenuItem.url));
   }
   return pathname
}

function determineCorrectUrlBasedOnQueryParameters(menuItems = [], queryObj, applicationGroup){
   const { APP_ID_KEY, MENU_ITEM_KEY } = config;
   const conditionMatch = m => (
       m.appId === queryObj[APP_ID_KEY] && queryObj[MENU_ITEM_KEY] === m.itemId
   );
   let menuItem = findMenuItem(menuItems, conditionMatch);
   if (!menuItem){
      menuItem = findFirstMenuItem(menuItems);
   }
   return getCleanUrl(
       applicationGroup,
       getAppIdentifier(menuItem.appId),
       getCorrectPath(menuItem.url)
   );
}

export {
   getApplicationGroup,
   getCorrectPath,
   getAppIdentifier,
   getCleanUrl,
   adjustIframeSrcUrl,
   parseQueryParameters,
   parseQueryObj,
   findMenuItem,
   constructAdditionalPathForIframeSrc,
   constructContextSwitcherOptions,
   determineUserLanguage,
   forceReload,
   findFirstMenuItem,
   determineCorrectUrlBasedOnPathname,
   determineCorrectUrlBasedOnQueryParameters
};
