import config from './config';

let globalSession;
const createGlobalSession = (appGroupConfig, rpSignout, rpRefreshSession)=>{
  const signinCallback = (idToken) => {
     console.log("Signin callback!  idToken = " + idToken);
     return Promise.resolve();
  };

/* Replacing the refresh session parameter with this stub until supposed defect is resolved */
  const stubRefreshFunc = () => {
      console.log("Hitting refresh function");
      return new Promise((res,rej) => {
	 setTimeout(() => { console.log("about to resolve refresh"); res(); }, 1000);
      });
  };
  return new Promise((resolve)=>{
    globalSession = window.globalSessionWidget.init({
        clientId: appGroupConfig.oktaClientId,
        signoutCallback: rpSignout,
        signinCallback: signinCallback,
        localSessionRefresh: stubRefreshFunc,
        env: config.kodama.gsmEnv
    });

    resolve();
  });
};

function initPartnerGUI(locale, appGroupConfig){

    const {ssoApplicationId} = appGroupConfig;
    let applicationId = ssoApplicationId || 'kodama';
   
    global.PartnerGlobalUserInterface.init({
        headerSelector: '#global-header',
        footerSelector: '#global-footer',
        env: config.kodama.gsmEnv,
        globalSession: globalSession,
        showProfile: true,
        applicationId,
        showHelpLink: true,
        userLanguage: locale
    });

}

export {createGlobalSession, globalSession, initPartnerGUI };
