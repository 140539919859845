import config from './config'

const { qualtricsEnvs } = config;

const baseUrl = process.env.PUBLIC_URL;

function loadQualtricScript(){
   const nodeEnv = window.deployEnv || 'local';
   if (qualtricsEnvs.includes(nodeEnv)){
      const qualtricScriptTag = document.getElementById("qualtric-script");
      if (qualtricScriptTag){
         qualtricScriptTag.setAttribute('src', `${baseUrl}/qualtric.js`)
      }
   }
}

export default { loadQualtricScript }
