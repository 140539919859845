import React, {Component} from 'react';
import Breadcrumb from 'react-bootstrap/lib/Breadcrumb';
import config from './config';
import './KodamaBreadcrumbs.css';


/**
  * appends to the end of the breadcrumb an unlinked item
  * triggered by post message event.  The message content should be {breadcrumb: <label>}.
  * the label is not persisted across rendering.
  */
class AppBreadcrumbs extends Component{
  constructor(props){
    super(props);
    this.state = {label: ''};
    this.renderItems = this.renderItems.bind(this);
    this.appBreadcrumbHandler = this.appBreadcrumbHandler.bind(this);
  }

  componentDidMount(){
		window.addEventListener('message', this.appBreadcrumbHandler);
	}
	componentWillUnmount(){
		window.removeEventListener('message', this.appBreadcrumbHandler);
	}
	appBreadcrumbHandler(e){
    if (e.data.message === config.KODAMA_APP_BREADCRUMB_MESSAGE){
      var payload = e.data.payload;
      if(payload && payload.breadcrumb.length >= 0){
          this.setState({label: payload.breadcrumb});
      }
    }
	}

  renderItems(){
    // console.log('AppBreadcrumbs: renderItems()');
    if(this.state.label){
        return <Breadcrumb.Item active>{this.state.label}</Breadcrumb.Item>
    }
    return null;
  }

  render(){
    // console.log('rendering AppBreadcrumbs');
    return (
      <React.Fragment>
        {this.renderItems()}
      </React.Fragment>
    )
  }
}

export default AppBreadcrumbs;
