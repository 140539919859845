import axios from 'axios';
import React, { Component } from 'react';
import { DataLayer, pageContext, userContext } from './analytics';
import config from './config';
import ErrorMsg from './ErrorMsg';
import {
   adjustIframeSrcUrl,
   constructAdditionalPathForIframeSrc,
   getAppIdentifier,
   getCleanUrl,
   getCorrectPath,
   parseQueryObj,
   parseQueryParameters,
   findMenuItem
} from './utils';

var kodamaAPIBase = config.kodama.apiUrl;

const failOverComponent = <ErrorMsg message="There was a problem reaching the app. Please try again later." />;

const { KA_PATH_KEY, MENU_ITEM_KEY, KODAMA_APP_PATH_MESSAGE, APP_ID_KEY, CONTEXT_VALUE  } = config;

export default class ProxyComponent extends Component {

   state = {failOverComponent: null, src: '' }

   resetIframe = () => {
      //clearing state.src defeats shouldComponentUpdate restrictions and forces iframe to refresh
      this.setState({ src: '' }, () => {
         this.fetchLandingPageUrl();
      });
   }
   parseQueryParameters = () => {
      // uses mutable history.location.search because this component is updated on history.push()
	   if (this.props.history.location.search){
         const queryParameterString = this.props.history.location.search.substring(1);
         if (queryParameterString){
            return parseQueryParameters(queryParameterString);
         }
      }
      return {};
   }
   onFrameLocationChange = (event) => {
      if (event.data.message === KODAMA_APP_PATH_MESSAGE){
         let { appIdentifier, path, applicationGroup } = this.props.match.params;
         const { payload } = event.data;
         if (payload.app_id){
            appIdentifier = getAppIdentifier(payload.app_id);
         }

         const queryObj = this.parseQueryParameters();
         if (payload[APP_ID_KEY]) queryObj[APP_ID_KEY] = payload[APP_ID_KEY];
         if (payload[MENU_ITEM_KEY]) queryObj[MENU_ITEM_KEY] = payload[MENU_ITEM_KEY];
         if (payload[CONTEXT_VALUE]) queryObj[CONTEXT_VALUE] = payload[CONTEXT_VALUE];
         if (payload.path) queryObj[KA_PATH_KEY] = payload.path;
         const searchParamStr = parseQueryObj(queryObj);
         const url = getCleanUrl(applicationGroup, appIdentifier, getCorrectPath(path));
         this.props.history.replace(url+searchParamStr, event.data.message);
      }
   }
   fetchLandingPageUrl = () => {
      const { appGroupContextType, menuItems = [] } = this.props;
      const { applicationGroup, appIdentifier, path } = this.props.match.params;
      let queryParamObject = Object.entries(this.parseQueryParameters()).reduce((acc, keyVal) => {

         if (keyVal[0] !== APP_ID_KEY && keyVal[0] !== MENU_ITEM_KEY) {
            acc[keyVal[0]] = keyVal[1];
         }

         return acc;
      }, {}), landingPageUrl = "", options = {};
      if (queryParamObject[CONTEXT_VALUE]){
         options.params = { contextValue: queryParamObject[CONTEXT_VALUE]};
      }

      const baseUrl = `${kodamaAPIBase}/navigation/v1/menuItem/app/${appIdentifier}`;
      axios.get(baseUrl + getCorrectPath(path), options).then((response) => {
         const permission = (response.data && response.data.permission) || ''

         landingPageUrl = response.data.landingPageUrl;
         const additionalParamsIndex = landingPageUrl.indexOf("?");

         //landing page has query params. Need to extract them from landing page

         if (additionalParamsIndex !== -1){
            const additionalParams = parseQueryParameters(landingPageUrl.substr(additionalParamsIndex+1));
            landingPageUrl = landingPageUrl.substring(0, additionalParamsIndex);
            queryParamObject = Object.assign({}, additionalParams, queryParamObject);
         }

         let additionalPath = constructAdditionalPathForIframeSrc(queryParamObject, appGroupContextType);

         let adjustedUrl = adjustIframeSrcUrl(landingPageUrl, additionalPath);

         this.setState({ src: adjustedUrl, failOverComponent: null }, () => {
            const page = pageContext.get();
            page.type = 'landing';
            page.appName = appIdentifier;
            const menuItem = findMenuItem(menuItems, item => (
               item.url === getCorrectPath(path)
            ))
            if (menuItem) {
               page.menuItem = menuItem.itemId
            }

            const user = userContext.get();
            user.permission = permission;
            const dataLayer = new DataLayer({ page, user });
            dataLayer.send('pageload')
         });
      }).catch((err) => {
         console.log(err);
         this.setState({src: '', failOverComponent});
      });
   }
   componentWillUnmount(){
      window.removeEventListener("message", this.onFrameLocationChange);
   }
   componentDidUpdate(prevProps, prevState){
      if (this.state.src === prevState.src && prevState.failOverComponent === this.state.failOverComponent){
         // if ProxyComponent updates without change in landingPageUrl, trigger setting landing page
         // this occurs when only param changes like context switch
         this.fetchLandingPageUrl();
      }
   }

   getContextParam = (key,search) => {
      if (search && search.substring(1)){
         const queryParameterString = search.substring(1);
         if (queryParameterString){
            return parseQueryParameters(queryParameterString)[key];
         }
      }
   }
   shouldComponentUpdate(nextProps, nextState){
      //this is necessary when transitioning from proxy component back to homepage
      const { appIdentifier, path } = this.props.match.params;
      const { appIdentifier: nextAppId, path: nextPath } = nextProps.match.params;
      const { action } = nextProps.history;

      const differentApp =  appIdentifier !== nextAppId || path !== nextPath; // change in appId or path
      const differentIframeSrc = nextState.src !== this.state.src;  //change in landingPageUrl
      const diffContext = this.getContextParam(CONTEXT_VALUE, this.props.location.search) !== this.getContextParam(CONTEXT_VALUE, nextProps.location.search);  // change in context
      const diffMenu = this.getContextParam(MENU_ITEM_KEY, this.props.location.search)
        ?this.getContextParam(MENU_ITEM_KEY, this.props.location.search) !== this.getContextParam(MENU_ITEM_KEY, nextProps.location.search)
        :false; // change in menu item if the menu item is already a parameter

      // this message is used to update the history and top level URL without updating the iframe.
      if(nextProps.location.state === KODAMA_APP_PATH_MESSAGE){
        return false;
      }

      if(differentApp && action === 'POP'){
        //X23-366.  side effect of iframe on the back button operation when the result
        //navigates to different multi page app.
         return false;
      }

      return differentApp || differentIframeSrc || diffContext || diffMenu || nextState.failOverComponent !== this.state.failOverComponent;
   }
   componentDidMount(){
      window.addEventListener("message", this.onFrameLocationChange);
      this.fetchLandingPageUrl();
   }
   render(){
      if (this.state.failOverComponent === null ){
         console.log('update iframe: '+this.state.src);
         return <iframe className="iframe-embedded-app" src={this.state.src}  title="Application Page" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>;
      } else {
         return this.state.failOverComponent;
      }
   }
}
