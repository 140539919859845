import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import UploadIcon from '../images/upload_icon.svg';
import './ImageUploaderSupport.css';

export const DefaultContent = () => (
    <div className="image-uploader-default-content-container">
       <img alt="" src={UploadIcon} />
       <div>Drag File to Upload</div>
       <div>or</div>
       <div className="btn btn-primary click-to-browse">Browse File</div>
    </div>
);

export const DisabledContent = () => (
    <div className="image-uploader-default-content-container">
        <Glyphicon glyph="remove-sign" />
        <div>File Upload Disabled</div>
    </div>
);

export const UploadedContent = ({ imageUrl }) => (
    <div className="uploaded-image">
        <img src={imageUrl} alt="uploaded icon" />
    </div>
);
